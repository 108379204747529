import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	ChangeDetectorRef
} from '@angular/core';
import { LogService } from '../../core/log/log.service';
import { EnvironmentService } from '../../../environments/environment.service';

@Component({
    selector: 'dottnet-message-in-page',
    templateUrl: './message-in-page.component.html',
    styleUrls: ['./message-in-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
    // ,	encapsulation: ViewEncapsulation.ShadowDom
    ,
    standalone: true
})
export class MessageInPageComponent implements OnInit {
	@Input() message: string;
	@Input() title_message: string;

	constructor(
		private logService: LogService,
		private changeDetectorRef: ChangeDetectorRef,
		private envService: EnvironmentService
	) {}

	ngOnInit(): void {
		this.logService.info('Message in page: ', this.message);
	}
}
